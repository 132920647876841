function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BreadcrumbLink',{staticClass:"block",attrs:{"to":{ name: 'organizationGeneralTab', params: { organizationId: _vm.id } },"assets":{ organization: _vm.displayName || _vm.name }}},[_c('Card',{staticClass:"cursor-pointer",class:{
      'border-red-600': _vm.expiredQuotas.length,
      'border-yellow-500': _vm.expiringQuotas.length
    }},[_c('div',{staticClass:"truncate flex items-center",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"flex-grow"},[_c('img',{staticClass:"h-6 mr-1 inline-block",attrs:{"src":require("@/assets/logo-delight.png"),"alt":"Delight logo"}}),_c('span',{staticClass:"poppins text-base align-middle"},[_vm._v(" "+_vm._s(_vm.displayName || _vm.name)+" ")])]),_c('div',{staticClass:"ml-auto"},[(_vm.expiredQuotas.length)?_c('Tooltip',[_c('fa',{staticClass:"text-red-600",attrs:{"icon":"do-not-enter","size":"lg"}}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t("THE_FOLLOWING_ROLES_ARE_EXPIRED"))+" "),_c('ul',_vm._l((_vm.expiredQuotas),function(ref){
    var id = ref.id;
    var name = ref.name;
return _c('li',{key:id},[_vm._v(" - "+_vm._s(name)+" ")])}),0)])],1):_vm._e(),(_vm.expiringQuotas.length)?_c('Tooltip',[_c('fa',{staticClass:"text-yellow-500",attrs:{"icon":"exclamation-triangle","size":"lg"}}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t("THE_FOLLOWING_ROLES_WILL_EXPIRES_SOON"))+" "),_c('ul',_vm._l((_vm.expiringQuotas),function(ref){
    var id = ref.id;
    var name = ref.name;
return _c('li',{key:id},[_vm._v(" - "+_vm._s(name)+" ")])}),0)])],1):_vm._e()],1)]),_c('div',{staticClass:"flex justify-around mb-3"},_vm._l((_vm.statsStyle),function(ref){
    var _obj;

    var key = ref.key;
    var rest$1 = objectWithoutProperties( ref, ["key"] );
    var rest = rest$1;return _c('Stat',_vm._b({key:key},'Stat',Object.assign({}, rest, {propKey: key}, ( _obj = {}, _obj[key] = _vm.$props[key], _obj )),false))}),1),_c('Quotas',{attrs:{"quotas":_vm.quotas}}),_vm._v(" "+_vm._s(_vm.$t("LAST_LOGIN"))+" : "),_c('LastLogin',{staticClass:"mt-3 text-xs text-right text-gray-500 italic",attrs:{"date":_vm.lastLogin}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }