<template>
  <Button @click="exportTenants" class="ml-2" size="large" :loading="loading">
    <fa icon="file-download" class="mr-2" /> {{ $t("EXPORT") }}
  </Button>
</template>

<script>
import tenants from "./queries/tenants.gql";
import { saveAs } from "file-saver";

export default {
  data: () => ({ loading: false }),
  methods: {
    exportTenants() {
      this.loading = true;

      this.$apollo
        .query({ query: tenants })
        .then(({ data: { tenants: { entries = [] } = {} } }) => {
          const csvContent = entries.reduce(
            (acc, { name, displayName, qlikApps }) =>
              name === "delight"
                ? acc
                : `${acc}"${displayName || name}","${qlikApps
                    .map(({ name }) => name)
                    .join(", ")}"\n`,
            `"${this.$t("ORGANIZATION")}","${this.$t("QLIK_APPLICATIONS")}"\n`
          );

          saveAs(
            new Blob([csvContent], { type: "text/csv;charset=utf-8" }),
            `${this.$t("ORGANIZATIONS")}.csv`
          );
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
