<template>
  <div>
    <div
      class="flex items-center my-1"
      v-for="(quotas, product) in perProductQuotas"
      :key="product"
    >
      <img
        :src="require(`@/assets/${QUOTAS[quotas[0].name].productIcon}`)"
        class="h-8 mr-3"
        alt="Product logo"
      />
      <div class="-mx-1">
        <Quota
          class="mx-1"
          v-for="{ id, ...rest } in quotas"
          :key="id"
          v-bind="rest"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { QUOTAS } from "@/assetsMapping.const.js";
import Quota from "./components/Quota.vue";

export default {
  components: { Quota },
  props: { quotas: { type: Array, default: () => [] } },
  computed: {
    QUOTAS: () => QUOTAS,
    perProductQuotas: ({ quotas }) =>
      quotas.reduce(
        (acc, { name, product = name.split("_")[0], ...rest }) => ({
          ...acc,
          [product]: [...(acc[product] || []), { name, ...rest }]
        }),
        {}
      )
  }
};
</script>

<style scoped></style>
