const sortByLastLogin = (a, b) =>
  Date.parse(b.lastLogin) - Date.parse(a.lastLogin);

export const getLastUserLogin = users => {
  const loggedInUsers = users.filter(({ lastLogin }) => lastLogin);
  return loggedInUsers.length
    ? loggedInUsers.sort(sortByLastLogin)[0].lastLogin
    : null;
};

export const getQuotasExpirationDates = roles =>
  roles.reduce((expirationDates, { expirationDate, ...rest }) => {
    if (!expirationDate) return expirationDates;
    const remainingDaysWarningThreshold = 4;
    const dayInSeconds = 1000 * 60 * 60 * 24;
    const remainingDays = Math.floor(
      (Date.parse(expirationDate) - new Date().valueOf()) / dayInSeconds
    );

    return [
      ...expirationDates,
      ...(remainingDays < remainingDaysWarningThreshold
        ? [{ ...rest, expirationDate, remainingDays }]
        : [])
    ];
  }, []);
