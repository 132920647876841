<template>
  <span>
    <Tooltip :delay="500" :content="$t(tooltipKey)">
      <fa :icon="icon" size="lg" fixed-width class="mx-1"></fa>
      <Tag
        :color="$attrs[propKey].length ? 'geekblue' : 'red'"
        class="font-bold"
      >
        {{ $attrs[propKey].length }}
      </Tag>
    </Tooltip>
  </span>
</template>

<script>
export default {
  props: {
    propKey: { type: String, required: true },
    icon: { type: String, required: true },
    tooltipKey: { type: String, required: true }
  }
};
</script>

<style scoped></style>
