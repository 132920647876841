<template>
  <Tooltip :delay="500" :content="$t(quota.labelKey)">
    <div class="flex flex-col items-center">
      <i-circle
        :percent="percent"
        :size="50"
        :stroke-color="circleColor"
        dashboard
      >
        {{ prefixedRemaining }}
      </i-circle>
      <fa :icon="quota.quotaIcon" class="-mt-3"></fa>
    </div>
  </Tooltip>
</template>

<script>
import { QUOTAS } from "@/assetsMapping.const.js";

export default {
  props: {
    name: { type: String, required: true },
    remaining: { type: Number, required: true },
    maxCapacity: { type: Number, required: true }
  },
  computed: {
    quota: ({ name }) => QUOTAS[name],
    percent: ({ remaining, maxCapacity }) =>
      +((remaining / maxCapacity) * 100 || 0).toFixed(0),
    circleColor: ({ percent }) => {
      if (percent === 100) return "#48bb78";
      if (percent > 49) return "#4299e1";
      if (percent > 10) return "#ed8936";
      return "#f56565";
    },
    prefixedRemaining: ({ remaining }) => {
      if (remaining < 1000) return remaining;
      if (remaining < 1000000) return `${Math.trunc(remaining / 1000)}k`;
      // We assume that quota will never be bigger than 999M
      return `${(remaining / 1000000).toFixed(1)}M`;
    }
  }
};
</script>

<style scoped></style>
