<template>
  <BreadcrumbLink
    :to="{ name: 'organizationGeneralTab', params: { organizationId: id } }"
    class="block"
    :assets="{ organization: displayName || name }"
  >
    <Card
      class="cursor-pointer"
      :class="{
        'border-red-600': expiredQuotas.length,
        'border-yellow-500': expiringQuotas.length
      }"
    >
      <div slot="title" class="truncate flex items-center">
        <div class="flex-grow">
          <img
            src="@/assets/logo-delight.png"
            class="h-6 mr-1 inline-block"
            alt="Delight logo"
          />
          <span class="poppins text-base align-middle">
            {{ displayName || name }}
          </span>
        </div>
        <div class="ml-auto">
          <Tooltip v-if="expiredQuotas.length">
            <fa icon="do-not-enter" size="lg" class="text-red-600"></fa>
            <div slot="content">
              {{ $t("THE_FOLLOWING_ROLES_ARE_EXPIRED") }}
              <ul>
                <li v-for="{ id, name } in expiredQuotas" :key="id">
                  - {{ name }}
                </li>
              </ul>
            </div>
          </Tooltip>
          <Tooltip v-if="expiringQuotas.length">
            <fa
              icon="exclamation-triangle"
              size="lg"
              class="text-yellow-500"
            ></fa>
            <div slot="content">
              {{ $t("THE_FOLLOWING_ROLES_WILL_EXPIRES_SOON") }}
              <ul>
                <li v-for="{ id, name } in expiringQuotas" :key="id">
                  - {{ name }}
                </li>
              </ul>
            </div>
          </Tooltip>
        </div>
      </div>
      <div class="flex justify-around mb-3">
        <Stat
          v-for="{ key, ...rest } in statsStyle"
          :key="key"
          v-bind="{ ...rest, propKey: key, [key]: $props[key] }"
        />
      </div>
      <Quotas :quotas="quotas" />
      {{ $t("LAST_LOGIN") }} :
      <LastLogin
        :date="lastLogin"
        class="mt-3 text-xs text-right text-gray-500 italic"
      />
    </Card>
  </BreadcrumbLink>
</template>

<script>
import { getLastUserLogin, getQuotasExpirationDates } from "./helpers.js";
import Quotas from "./components/Quotas/Quotas.vue";
import Stat from "./components/Stat/Stat.vue";
import BreadcrumbLink from "@/components/BreadcrumbLink/BreadcrumbLink.vue";
import LastLogin from "@/components/LastLogin/LastLogin.vue";

const statsStyle = [
  { key: "users", icon: "users", tooltipKey: "USERS" },
  { key: "roles", icon: "tags", tooltipKey: "ROLES" },
  { key: "qlikApps", icon: "database", tooltipKey: "QLIK_APPLICATIONS" }
];

export default {
  props: {
    id: { type: [Number, String], required: true },
    name: { type: String, required: true },
    displayName: { type: String },
    users: { type: Array, required: true },
    roles: { type: Array, required: true },
    qlikApps: { type: Array, required: true },
    quotas: { type: Array, required: true }
  },
  components: { Quotas, Stat, BreadcrumbLink, LastLogin },
  computed: {
    statsStyle: () => statsStyle,
    lastLogin: ({ users }) => getLastUserLogin(users),
    expirationDates: ({ roles }) => getQuotasExpirationDates(roles),
    expiredQuotas: ({ expirationDates }) =>
      expirationDates.filter(({ remainingDays }) => remainingDays < 0),
    expiringQuotas: ({ expirationDates }) =>
      expirationDates.filter(({ remainingDays }) => remainingDays > -1)
  }
};
</script>

<style scoped></style>
